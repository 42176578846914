import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  entradas: [],
  totalCount: 0,
  loading: false,
  dataTipoEntradaGraphic: [],
}

export const Slice = createSlice({
  name: 'ent',
  initialState,
  reducers: {
    setEntradas: (state, action) => {
      state.entradas = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setDataTipoEntrada: (state, action) => {
      state.dataTipoEntradaGraphic = action.payload
    },
  },
})

export const { setDataTipoEntrada, setEntradas, setTotalCount, setLoading } =
  Slice.actions
export default Slice.reducer

import Grid from '@mui/material/Grid'
import Chart from './components/Chart'
import Salidas from './components/Salidas'
import SolicitudPend from './components/SolicitudesPend'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import Paper from '@mui/material/Paper'
import Title from './components/Title'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import DashData from './components/DashData'
import Link from '@mui/material/Link'
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom'
import useDashResumen from '../../Hooks/useDashResumen'
import { useSelector } from 'react-redux'
import useDashPorcentaje from '../../Hooks/useDashPorcentaje'
import useDashSalidas from '../../Hooks/useDashSalidas'
import useSolPendParCant from '../../Hooks/useSolPendParCant'
import useDashSolicitudPend from '../../Hooks/useDashSolicituPend'
import { useState } from 'react'
import * as React from 'react'

const DashboardPage = () => {
  const {
    resumen,
    porcentajeSalEnt,
    salidas,
    solicitudesPend,
    isResumenLoading,
    isPorcentajeSalEntLoading,
    isSalidasLoading,
    isSolicitudesPendLoading,
  } = useSelector(state => state.dash)
  const [q, setQ] = useState({
    lastMonth: false,
  })
  useDashResumen(q)
  useDashPorcentaje(q)
  useDashSalidas(q)
  useSolPendParCant(q)
  useDashSolicitudPend(q)

  const nav = useNavigate()
  const preventDefault = event => {
    event.preventDefault()
    nav(`/solicitudes`)
  }

  return (
    <>
      <Grid container sx={{ mb: 2 }}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
            }}>
            <Typography component="h1" variant="h5" color="inherit">
              Resumen{' '}
              {q.lastMonth
                ? moment().subtract(1, 'month').format('MMM YYYY')
                : moment().format('MMM YYYY')}
            </Typography>
            {!q.lastMonth && (
              <Button
                onClick={() => {
                  setQ({ lastMonth: true })
                }}>
                Mes Anterior
              </Button>
            )}
            {q.lastMonth && (
              <Button
                onClick={() => {
                  setQ({ lastMonth: false })
                }}>
                Mes Siguiente
              </Button>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <DashData
                label="Productos salientes"
                data1={resumen?.salidas ?? 0}
                variant="danger"
                loading={isResumenLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <DashData
                label="Productos entrantes"
                data1={resumen?.entradas ?? 0}
                variant="success"
                loading={isResumenLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <DashData
                label="Solicitudes"
                data1={resumen?.solicitudes ?? 0}
                variant="secondary"
                loading={isResumenLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <DashData
                label="Sol. Pendientes"
                data1={resumen?.solPendientes ?? 0}
                variant="warning"
                loading={isResumenLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <DashData
                label="Sol. Completadas"
                data1={resumen?.solCompletadas ?? 0}
                variant="secondary"
                loading={isResumenLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <DashData
                label="Sol. Parciales"
                data1={resumen?.solParciales ?? 0}
                variant="secondary"
                loading={isResumenLoading}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Chart */}
        <Grid item xs={12} md={6}>
          <Chart
            entrantes={porcentajeSalEnt.entradas ?? 0}
            salientes={porcentajeSalEnt.salidas ?? 0}
            loading={isPorcentajeSalEntLoading}
          />
        </Grid>

        {/* Recent Orders */}
        <Grid item xs={12}>
          <Salidas rows={salidas} loading={isSalidasLoading} />
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Title>
              Solicitudes Pendientes{' '}
              {isSolicitudesPendLoading && <CircularProgress size={15} />}
            </Title>
            <TableContainer>
              <Table aria-labelledby="tableTitle" size={'small'}>
                <TableHead>
                  <TableRow>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Nombre solicitud</TableCell>
                    <TableCell>Solicitante</TableCell>
                    <TableCell>Nombre tienda</TableCell>
                    <TableCell>Direccion</TableCell>
                    <TableCell>Entregar a</TableCell>
                    <TableCell>Estado solicitud</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {solicitudesPend.map((row, index) => (
                    <SolicitudPend key={row.id} idx={index} row={row} />
                  ))}
                </TableBody>
                <Link
                  href={'/solicitudes'}
                  color="primary"
                  onClick={preventDefault}
                  sx={{ mt: 3 }}>
                  Ver más
                </Link>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default DashboardPage

import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from '@mui/material'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import ArchiveIcon from '@mui/icons-material/Archive'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import WarningIcon from '@mui/icons-material/Warning'
import { numericFormatter } from 'react-number-format'
import * as React from 'react'

const DashData = ({ variant, data1, label, loading = false }) => {
  const variants = {
    danger: {
      backgroundColor: '#FFE2E5',
      color: '#F64E60',
      icon: <LocalShippingIcon sx={{ color: '#fff' }} />,
    },
    success: {
      backgroundColor: '#C9F7F5',
      color: '#12827C',
      icon: <ArchiveIcon sx={{ color: '#fff' }} />,
    },
    secondary: {
      backgroundColor: '#EBEDF3',
      color: '#181C32',
      icon: <BookmarkIcon sx={{ color: '#fff' }} />,
    },
    warning: {
      backgroundColor: '#FFF4DE',
      color: '#FFA800',
      icon: <WarningIcon sx={{ color: '#fff' }} />,
    },
  }
  return (
    <Paper
      sx={{
        py: 1,
        px: 2,
        backgroundColor: variants[variant].backgroundColor,
      }}>
      <Box
        sx={{
          mb: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            backgroundColor: variants[variant].color,
            width: 40,
            height: 40,
            borderRadius: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: 3,
          }}>
          {variants[variant].icon}
        </Box>

        <Typography variant="h5" color={variants[variant].color}>
          {numericFormatter(data1.toString(), {
            thousandSeparator: '.',
            decimalSeparator: ',',
            decimalScale: 0,
          })}
        </Typography>
      </Box>
      <Divider className="divider" />
      <Typography
        variant="subtitle2"
        color={variants[variant].color}
        sx={{ mt: 1, gap: 1, display: 'flex', alignItems: 'center' }}>
        {label}
        {loading && <CircularProgress color={'inherit'} size={15} />}
      </Typography>
    </Paper>
  )
}

export default DashData

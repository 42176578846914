import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  resumen: {},
  isResumenLoading: false,
  porcentajeSalEnt: {},
  isPorcentajeSalEntLoading: false,
  salidas: [],
  isSalidasLoading: false,
  solicitudesPend: [],
  isSolicitudesPendLoading: false,
}

export const Slice = createSlice({
  name: 'dash',
  initialState,
  reducers: {
    setResumen: (state, action) => {
      state.resumen = action.payload
    },
    setIsResumenLoading: (state, action) => {
      state.isResumenLoading = action.payload
    },
    setPorcentajeSalEnt: (state, action) => {
      state.porcentajeSalEnt = action.payload
    },
    setIsPorcentajeSalEntLoading: (state, action) => {
      state.isPorcentajeSalEntLoading = action.payload
    },
    setSalidas: (state, action) => {
      state.salidas = action.payload
    },
    setIsSalidasLoading: (state, action) => {
      state.isSalidasLoading = action.payload
    },
    setSolicitudesPend: (state, action) => {
      state.solicitudesPend = action.payload
    },
    setIsSolicitudesPendLoading: (state, action) => {
      state.isSolicitudesPendLoading = action.payload
    },
  },
})

export const {
  setResumen,
  setIsResumenLoading,
  setPorcentajeSalEnt,
  setIsPorcentajeSalEntLoading,
  setSalidas,
  setIsSalidasLoading,
  setSolicitudesPend,
  setIsSolicitudesPendLoading,
} = Slice.actions
export default Slice.reducer

import { setIsResumenLoading, setResumen } from '../dashRedux'
import { getResumen } from '../restFunctios/getResumen'

export const getResumenAction = q => {
  return async dispatch => {
    dispatch(setIsResumenLoading(true))
    const { data } = await getResumen(q)
    if (data) {
      dispatch(setResumen(data))
      dispatch(setIsResumenLoading(false))
    } else {
      dispatch(setResumen({}))
      dispatch(setIsResumenLoading(false))
    }
  }
}

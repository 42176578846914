import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  tipos_traslado: [],
  totalCount: 0,
}

export const Slice = createSlice({
  name: 'tipos_traslado',
  initialState,
  reducers: {
    setTiposTraslado: (state, action) => {
      state.tipos_traslado = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
  },
})

export const { setTiposTraslado, setTotalCount } = Slice.actions
export default Slice.reducer

import { setIsPorcentajeSalEntLoading, setPorcentajeSalEnt } from '../dashRedux'
import { getPorcentajesSalEnt } from '../restFunctios/getPorcentajeSalEnt'

export const getPorcentajeSalEntAction = q => {
  return async dispatch => {
    dispatch(setIsPorcentajeSalEntLoading(true))
    const { data } = await getPorcentajesSalEnt(q)
    if (data) {
      dispatch(setPorcentajeSalEnt(data))
      dispatch(setIsPorcentajeSalEntLoading(false))
    } else {
      dispatch(setPorcentajeSalEnt({}))
      dispatch(setIsPorcentajeSalEntLoading(false))
    }
  }
}

import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/reportes'

export const getStockActual = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/getStockActual`, { params: q })
export const getStockBodega = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/getStockBodega`, { params: q })
export const getStockCanales = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/getStockCanales`, { params: q })
export const getStockDetalle = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/getStockDetalle`, { params: q })
export const getStockCritico = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/getStockCritico`, { params: q })
export const getStockBodGraphic = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/getStockBodGraphic`, { params: q })
export const getStockActualGroupByCat = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/getStockActualGroupByCat`, { params: q })
export const getStockCanGraphic = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/getStockCanGraphic`, { params: q })
export const getStockActualGroupByMarca = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/getStockActualGroupByMarca`, {
    params: q,
  })
export const getStockActualGroupBySubCat = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/getStockActualGroupBySubCat`, {
    params: q,
  })
export const getDemanda = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/getDemanda`, { params: q })
export const getDesviaciones = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/getDesviaciones`, { params: q })

export const getStockSalidasGraphic = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/getStockSalidasGraphic`, { params: q })
export const getStockEntradasGraphic = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/getStockEntradasGraphic`, { params: q })

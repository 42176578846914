import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  tiendas: [],
  totalCount: 0,
  loading: false,
}

export const Slice = createSlice({
  name: 'tiendas',
  initialState,
  reducers: {
    setTiendas: (state, action) => {
      state.tiendas = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setTiendas, setTotalCount, setLoading } = Slice.actions
export default Slice.reducer

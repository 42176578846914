import * as React from 'react'
import { PieChart } from '@mui/x-charts'
import { Box, CircularProgress, Paper, Typography } from '@mui/material'

export default function Chart({ entrantes, salientes, loading = false }) {
  return (
    <React.Fragment>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          height: '100%',
          maxHeight: 302,
        }}>
        <Box
          sx={{
            position: 'absolute',
            top: 8,
            right: 13,
          }}>
          <Typography component="h1" variant="h5" color="inherit">
            {loading && <CircularProgress size={15} />}
          </Typography>
        </Box>
        <PieChart
          series={[
            {
              data: [
                {
                  id: 0,
                  value: entrantes,
                  label: 'Entrantes',
                  color: '#12827C',
                },
                {
                  id: 1,
                  value: salientes,
                  label: 'Salientes',
                  color: '#F64E60',
                },
              ],
              valueFormatter: v => `${v.value}%`,
              innerRadius: 30,
              paddingAngle: 1,
              cornerRadius: 5,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 15, additionalRadius: -15, color: 'gray' },
            },
          ]}
        />
      </Paper>
    </React.Fragment>
  )
}

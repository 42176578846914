import axios from 'axios'
import { API_URL } from './Cnx'

const REQUEST_URL = 'api/solicitudes'

export const insertSolicitud = (s, d) =>
  axios.post(`${API_URL}/${REQUEST_URL}/`, {
    solicitud: {
      ...s,
      detalle: d,
    },
  })
export const insertSolicitudXLS = data =>
  axios.post(`${API_URL}/${REQUEST_URL}/insertSolicitudXLS`, data)
export const updateSolicitud = (id, s, d) =>
  axios.put(`${API_URL}/${REQUEST_URL}/${id}`, {
    solicitud: {
      ...s,
      detalle: d,
    },
  })
export const deleteSolicitud = id =>
  axios.delete(`${API_URL}/${REQUEST_URL}/${id}`)
export const getSolicitudes = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/`, { params: q })
export const getSolPendParCant = q =>
  axios.get(`${API_URL}/${REQUEST_URL}/getSolPendParCant`, { params: q })

export const getSolicitud = id => axios.get(`${API_URL}/${REQUEST_URL}/${id}`)
